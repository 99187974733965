import { Component, OnInit } from '@angular/core';
import { CustomService } from 'src/app/custom.service';
import { environment } from 'src/environments/environment';
import { Directive } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { SeoService } from 'src/app/seo.service';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    Page:any;
    value:any;
    quality:any;
    prompt:any;
    innovative:any;

    api:any;
    popup = false;
    Clients:any;
    jump:any;
    Banner:any;
    availableclients = false;
    availablebanners = false;
     refernce= {   
        nav:true, 
        dots: false,
        navigation: true,
        autoplay:true,
        loop:true,
         items:7,
        responsive:{
            0:{
                items:3,
                navigation:true
            },
            600:{
                items:7,
                nav:true
            },
            900:{
                items:7,
                nav:true
            }
        } 
    }
  constructor(public customservice:CustomService,public route : ActivatedRoute,public seoService:SeoService,private titleService: Title) {
 
    this.value ="<p>Foil stamping<br>Embossing<br>Metallised printing (UV printing on metallised polyester)<br>Window patching<br>Drip off effect<br>Online aqueous varnish (gloss, matt, pearlescent)<br>Hot melt gluing<br>LDPE coated cartons<br>Water resistant cartons</p>";
    this.quality =" <p>Combination of the right machinery, systems and trained manpower ensure consistent quality</p><br><p>Documented standard operating procedures for every activity</p><br><p>Regular training and skill upgradation programs for the staff</p><br><p>In house quality control laboratory with the latest testing equipments</p>";
    this.prompt =" <p>Online Job Tracking facility<br>Prompt and pro-active customer care team<br>Shorter delivery lead period.<br>Quick quotes</p>";
    this.innovative =" <p>Experienced and qualified creative team offers attractive design options along with the right structure and engineering lay outs.</p><br><p>The design team suggests the right substrate depending upon the size, weight , stacking and packing need of the product. They ensure that resources are not wasted by selecting a substrate higher or lower than the requirement.</p><br><p>We enable saving on logistics cost by providing high bulk boards that are lighter in weight.</p><br><p>Neatly made mock ups help the client see, touch and feel the product before</p>";
   

          

   }



  ngOnInit(): void {
    this.route.queryParams.subscribe(
        (data:any)=>{
            if(data){
             this.jump = data.g;
                 goto(this.jump);
            }
          
        }
    )
    this.api  = environment;

       this.customservice.getpages('home').subscribe(
        (data:any) => {
                 if (data.cmsdetails.PageTitle != '') {
                        this.Page = data.cmsdetails;
                        this.titleService.setTitle(this.Page.MetaTitle);
                        let updatedesc = { name: 'description', content: this.Page.MetaDesc};
                        this.seoService.updateDesc(updatedesc);
                        let updateTitle = {name: 'keywords', content: this.Page.Keywords};
                        this.seoService.updateTitle(updateTitle);
                    
                 }
          });

    this.customservice.homeclients().subscribe(
        (data:any)=>{
            if (data.Clients.length > 0) {
                this.Clients =data.Clients;
                this.availableclients = true;
            } 
     
         });

    this.customservice.getBanners().subscribe(
        (data:any)=>{
            if (data.banners.length > 0) {
                this.Banner =data.banners;
                this.availablebanners = true;
            } 
     
         });
  }
  

}

function goto(eventid){
   setTimeout(function(){
       window.document.getElementById(eventid).scrollIntoView({ behavior: 'smooth', block: 'center' });
   }, 1000);
}

@Directive({
    selector: 'video[autoplay]',
    host: {
        'autoplay': '',
        'oncanplay': 'this.play()',
        'onloadedmetadata': 'this.muted = true'
    }
})
export class VideoAutoplayDirective {}