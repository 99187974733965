import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'onlinegraphics';
  constructor(
    private router: Router
) {

  this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
          // trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
          gtag('config', 'UA-30333661-1', {'page_path': evt.urlAfterRedirects});
      }
  });

}
}
