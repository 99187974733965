<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-lg-2 col-md-2 col-sm-6 col-6">
				<div class="home-logo">
				<a href="/" title="Folding Cartons, Mono Cartons, Paperboard Boxes, Cardboard Boxes and Packaging Boxes Manufacturer, Exporter and Supplier in Ahmedabad, Gujarat, India"><img alt="Leading Exporters of Folding Carton, Mono Carton, and Packaging Box in Nepal, Kenya, Tanzania, Uganda, Rwanda, UK, Netherlands, Ethiopia, Nigeria, Congo, Dubai, UAE, Qatar, Doha, Zambia, Zimbabwe." width="183" height="83" class="img-fluid" src="assets/images/home-logo.png"></a>
      </div>
			</div>
      <div class="col-sm-6 col-6 d-lg-none d-md-none align-self-center">


        <div class="hed-right-top">
        <div class="head-right risponsive">
            <div class="call">
                <a target="_blank" href="tel:+917069900057">
                <!--<img src="assets/images/phone.png">-->
                  <img width="30" height="24" src="assets/images/phone-new-icon.png">
                </a></div>
            <div>
                <a href="mailto:info@onlineprintandpack.com"> <!--<img src="assets/images/email-icon.png">-->
                  <img width="30" height="24" src="assets/images/mail-new-icon.png">
                </a>
            
        </div>
         
        </div>

               <button class="navbar-toggler float-end" type="button" (click)="toogleMenu()">
                <span class="navbar-toggler-icon">   
                    <i [ngClass]="(openSidebar)?'far fa-window-close':'fas fa-bars'" style="color:#fff; font-size:28px;"></i>
                </span>
              </button>

            </div>
            </div>
<div class="col-lg-10 col-md-10 col-sm-12 col-12">
<div class="head-right hed-right-inn">
	<div class="call"><!--<img src="assets/images/phone.png">-->
    <img src="assets/images/phone-new-icon.png">
	<a target="_blank" href="tel:+917069900057">+91-7069900057  </a>&nbsp; /
	<a target="_blank" href="tel:+917069900058">7069900058</a></div>
	<div><!--<img src="assets/images/email-icon.png">--> <img src="assets/images/mail-new-icon.png">
	<a href="mailto:info@onlineprintandpack.com">info@onlineprintandpack.com</a></div>
 
</div>


			   	 <div class="nav-bar d-lg-block d-md-block d-none">
			<nav class="navbar">

  <ul class="navbar-nav">
   
    <li class="nav-item">
        <a class="nav-link" title="Folding Cartons for Pharmaceuticals, Food, FMCG and Textiles - Online Print & Pack" href="/">Home </a>
      </li>

     <!--  <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">  Treeview menu  </a>
        <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#"> Dropdown item 1 </a></li>
        <li><a class="dropdown dropdown-item" href="#"> Dropdown item 2 » </a>
           <ul class="sub-menu dropdown-menu">
            <li><a class="dropdown-item" href="#">Submenu item 1</a></li>
            <li><a class="dropdown-item" href="#">Submenu item 2</a></li>
            <li><a class="dropdown-item" href="#">Submenu item 4</a></li>
            <li><a class="dropdown-item" href="#">Submenu item 5</a></li>
         </ul>
        </li></ul>
    </li> -->


<!--------   submenu in submenu start --->
<li class="dropdown">
    <a (click)="aboutrout()" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" href="javascript:;">  About Us</a>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a class="dropdown-item" title="Offset Printing and Packaging Company In India" href="/who-we-are">Who We are</a></li>
       <li><a class="dropdown-item" title="Specialize in Packaging at Competitive Pricing - Online Print & Pack" href="/vision">Vision & Mission</a></li>
              <li><a class="dropdown-item" title="Quality Control Laboratory Equipped with Latest Instruments – Online Print & Pack" href="/quality">Quality</a></li>
              <li><a class="dropdown-item" title="Online Print & Pack Commitment to Green" href="/green">Commitment To Green</a></li>
                 <li class="dropdown-submenu">
            <a class="dropdown-item"  href="javascript:;">Why Choose Us?</a>
            <ul class="dropdown-menu whychoose-menu" role="menu">
                <li><a class="dropdown-item" title="Foil Stamping, Embossing, Metallised Printing, Window Patching at Competitive Pricing" href="/value">Value Added Products At Competitive Prices</a></li>
              <li><a title="Quality Control Laboratory with Testing Equipments - Online Print & Pack" class="dropdown-item" href="/qualitycontrol">Quality</a></li>
              <li><a class="dropdown-item" title="Why Choose Online Print & Pack Pvt. Ltd.?" href="/promptcustomer">Prompt Customer Service Packaging Solutions</a></li>
              <li><a class="dropdown-item" title="Packaging Solutions – Innovative and Creative" href="/innovativecreative">Innovative & Creative  Packaging Solutions</a></li>
            </ul>
        </li>
    </ul>
</li>



    <li>
    <div class="dropdown">
    
        <a [routerLink]="['/']" [queryParams]="{g: 'product'}" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          Products
        </a>
       
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li><a title="Pharmaceutical Packaging, Folding Cartons Manufacturers and Suppliers in Gujarat India" class="dropdown-item" href="products/pharmaceuticals">Pharmaceuticals</a></li>
          <li><a class="dropdown-item" title="Food Packaging Boxes, Ice Cream Cartons Manufacturers & Suppliers" href="products/food">Food</a></li>
          <li><a class="dropdown-item" title="FMCG Packaging Solutions - Toothpaste, Soap, Perfumes Box Packaging" href="products/fmcg">FMCG</a></li>
          <li><a class="dropdown-item" title="Textile Packaging Boxes – E-commerce Fashion & Apparel Cartons" href="products/textile">Textiles</a></li>
        
        </ul>
        
      </div>
    </li>


   
    <li class="nav-item">
      <a class="nav-link" title="Packaging Cartons Design Studio, Printing & Post-Printing Facility @ Online Print & Pack" href="/infrastructure">Infrastructure</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" title="Events @ Online Print & Pack" href="/events">Events</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" title="Clientele - Online Print & Pack" href="/clients">Clientele </a>
    </li>
     <li class="nav-item">
      <a class="nav-link" title="Career at Online Print & Pack" href="/career"> Careers </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" title="Online Print & Pack Pvt. Ltd. Ahmedabad, Gujarat, India" href="/contact-us"> Contact Us</a>
    </li>
  </ul>

</nav>




</div>
</div>


		</div>
	</div>
</header>




<!-- mobile menu -->
<div class="navbar-side" id="navbarSide" [ngClass]="openSidebar ? 'reveal' : ''">
    <ul class="p-0">
        <li class="nav-item">
            <a class="nav-link avtive" (click)="toogleMenu()" href="/">Home</a>
          </li>
         <!-- <li class="nav-item dropdown ab-main-mobile" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
            <a class="nav-link dropdown-toggle navbar-side-item ab-mobile" href="/about-us" >About Us</a> <div class="ab-icon" (click)="toogleAboutSwap()"><i class="fas fa-caret-down"></i></div>
        </li>-->

         <li class="nav-item dropdown" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" href="/about-us" id="navbarDropdown" role="button" data-toggle="dropdown">
            <a class="nav-link dropdown-toggle navbar-side-item" (click)="toogleAboutSwap()" >About Us</a>
        </li>

              
    <div *ngIf="AboutSwap" class="bg-white mb-drop">
      <a class="nav-link" (click)="toogleMenu()" routerLink="/who-we-are">Who We Are</a>
    <a class="nav-link" (click)="toogleMenu()" routerLink="/vision">Vision & Mission</a>
     <a class="nav-link" (click)="toogleMenu()" routerLink="/quality">Quality</a>   
     <a class="dropdown-item" (click)="toogleMenu()" href="/green">Commitment To Green</a> 
     <a  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
             class="nav-link dropdown-toggle nav-item dropdown navbar-side-item why-choose" (click)="toogleWhySwap()">Why Choose Us?</a>
        <div *ngIf="WhySwap" class="bg-light-gray mb-drop">
        
    <a class="nav-link" (click)="toogleMenu()" routerLink="/value">Value Added Products At Competitive Prices</a>
     <a class="nav-link" (click)="toogleMenu()" routerLink="/qualitycontrol">Quality</a>
     <a class="nav-link"(click)="toogleMenu()"  routerLink="/promptcustomer">Prompt Customer Service Packaging Solutions</a>
     <a class="nav-link" (click)="toogleMenu()" routerLink="/innovativecreative">Innovative & Creative Packaging Solutions</a> 
     </div>
     </div> 


     <li class="nav-item dropdown" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
            <a class="nav-link dropdown-toggle navbar-side-item" (click)="tooglePorductSwap()">Products</a>
        </li>
              
    <div *ngIf="ProductSwap" class="bg-white mb-drop">
        
    <a class="nav-link" (click)="toogleMenu()" routerLink="/products/pharmaceuticals">Pharmaceuticals</a>
     <a class="nav-link" (click)="toogleMenu()" routerLink="/products/food">Food</a>
     <a class="nav-link" (click)="toogleMenu()" routerLink="/products/fmcg">FMCG</a>
     <a class="nav-link" (click)="toogleMenu()" routerLink="/products/textile">Textile</a> 
     </div>

        <li class="nav-item">
            <a class="nav-link avtive" (click)="toogleMenu()" href="/infrastructure">Infrastructure</a>
          </li>
          <li class="nav-item">
            <a class="nav-link avtive" (click)="toogleMenu()" href="/events">Events</a>
          </li>
          <li class="nav-item">
            <a class="nav-link avtive" (click)="toogleMenu()" href="/clients">Clientele </a>
          </li>
          <li class="nav-item">
            <a class="nav-link avtive" (click)="toogleMenu()" href="/carrer">Carrers </a>
          </li>

          
          <li class="nav-item">
            <a class="nav-link" (click)="toogleMenu()" routerLink="/contact-us">Contact Us</a>
          </li>

    </ul>

    <a href="javascript:;" class="close-sidebar pull-right" (click)="toogleMenu()">
        <i class="fas fa-chevron-left"></i>
    </a>
</div>
<!-- End -->

