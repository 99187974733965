

<footer class="main-footer">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-12 col-sm-12 col-12">
				<div class="row">
					<div class="col-lg-4 col-md-12 col-sm-12 col-12">
						<div class="quik-links">
						<h4>Quick Links</h4>
						<ul>
							<li><a href="/" title="Folding Cartons for Pharmaceuticals, Food, FMCG and Textiles - Online Print & Pack">Home</a></li>
							<li><a title="Quality Control Laboratory Equipped with Latest Instruments – Online Print & Pack" href="/quality">Quality</a></li>
							<li><a title="Packaging Cartons Design Studio, Printing & Post-Printing Facility @ Online Print & Pack" href="/infrastructure">Infrastructure</a></li>
                            <li><a title="Specialize in Packaging at Competitive Pricing - Online Print & Pack" href="/vision">Vision & Mission</a></li>
							<!--<li><a href="/events">Events</a></li>-->
                            
							<li><a title="Clientele - Online Print & Pack" href="/clients">Clientele</a></li>
							<li><a title="Career at Online Print & Pack" href="/career">Careers</a></li>
							<li><a title="Information on Folding/Mono Cartons, Paperboard/Cardboard Box Packaging" href="/blog">Blogs</a></li>
							<li><a title="Online Print & Pack Pvt. Ltd. Ahmedabad, Gujarat, India" href="/contact-us">Contact Us</a></li>
						</ul>
					</div> 
					</div>
						<div class="col-lg-8 col-md-12 col-sm-12 col-12">
				<div class="quik-links folding fot-pad-top">
					<h4>Folding Cartons</h4>
						<ul class="footer-pro-list fot-pad-bot">
							<li><a title="Pharmaceutical Packaging, Folding Cartons Manufacturers and Suppliers in Gujarat India" href="/products/pharmaceuticals">Pharmaceuticals</a></li>
							<li><a title="Food Packaging Boxes, Ice Cream Cartons Manufacturers & Suppliers" href="/products/food">Food</a></li>
							<li><a title="FMCG Packaging Solutions - Toothpaste, Soap, Perfumes Box Packaging" href="/products/fmcg">FMCG</a></li>
							<li><a title="Textile Packaging Boxes – E-commerce Fashion & Apparel Cartons" href="/products/textile">Textile</a></li>
						</ul>
				</div>
	            <div class="quik-links other-links fot-pad-top">
					<h4>Other Products</h4>
						<ul class="footer-pro-list">
				            <li><a title="Paper Labels for Syrup Bottles, Drinks, Injectibles, Bottled Food" href="/products/paper-labels">Paper Labels</a></li>
				            <li><a title="Offset Printed Promotional Material - Catalogues, Flyers, Danglers Manufacturer" href="/products/promotional-material">Promotional Material</a></li>
				            <li><a title="Calendar Printing - Wall/Desk Calendars Custom Printed Design, Finishing & Fabrication" href="/products/calendar-printing">Calendars</a></li>
				            <li><a title="Laminate Folders Manufacturers - Enhance Brand Value & Sales" href="/products/laminate-folders">Laminate Folders</a></li>
				            <li><a title="Leaflets Printing -  Unfolded, Pre-folded, Single/Multi-colour Paper Leaflet" href="/products/leaflet-printing">Leaflets</a></li>
				            <li><a title="Books Designing, Printing and Binding - Online Print & Pack" href="/products/book-printing">Books</a></li>
				            <li><a title="Paper Bags - Plain & Custom Printed Paper Bags Manufacturers" href="/products/paper-bags">Paper Bags</a></li>
	            		</ul>
	            </div>
			</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-12 col-sm-12 col-12">
				<div class="row">
							<div class="col-lg-6 col-md-12 col-sm-12 col-12">
				<div class="quik-links fot-pad-top quik-links-top">
					<h4>Contact Us</h4>
						<div class="footer-contact">
							<div class="footer-phone-img">
								<img width="24" height="24" src="assets/images/footer-phone-icon.png">
							</div>
							<div class="footer-phone">
								<span>Phone :</span><br>
								<a href="tel:+917069900057">+91-7069900057</a><br>
								<a href="tel:+917069900058">+91-7069900058</a>
							</div>
						</div>

						<div class="footer-contact bottom">
							<div class="footer-phone-img">
								<img width="24" height="24"  src="assets/images/email-icon-footer.png">
							</div>
							<div class="footer-phone">
								<span>Email :</span><br>
								<a href="mailto:info@onlineprintandpack.com">info@onlineprintandpack.com</a>
							</div>
						</div>

		<!-- <div class="social-icon">
		<a href="#"><img src="assets/images/facebook.png"></a>
		<a href="#"><img src="assets/images/instagram.png"></a>
		<a href="#"><img src="assets/images/youtube.png"></a>
		<a href="#"><img src="assets/images/t-icon.png">	</a>
		<a href="#"><img src="assets/images/whatsapp.png">	</a>
	</div> -->


				</div>
			</div>
				<div class="col-lg-6 col-md-12 col-sm-12 col-12">
					<div class="quik-links fot-pad-top">
						<h4>Plant & Office</h4>
							<p class="address">Online Print & Pack Pvt. Ltd. (OPPL)<br>
								Survey No: 32,<br>
                                 P.O. Chacharwadi Vasana,<br>
								 Sarkhej-Bavla Road, Sanand,<br>
								  Ahmedabad - 382 213, Gujarat, India.</p>
					</div>
			</div>
				</div>
			</div>
		</div>
	</div>
</footer>




<section class="copyright">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-12">
				<div class="copyright-inn">
				<p>© 2021 Online Print & Pack Private Limited. All Rights Reserved.</p>
			</div>
			</div>
		</div>
	</div>
	
</section>

<div class="floatWhatsApp">
	<a href="https://wa.me/+919825008952" class="whatsApplink" target="_blank"><img src="../../assets/images/whatsapp.png"></a>
	</div>




<!--- form start ---->

	<div class="quick_con">
		<div class="come_go" (click)="Quickform=true">
			<h3>
				Get A Quote
			</h3>
		</div>
	</div>



	<div class="inquiry-form" *ngIf="Quickform">
		<div class="fm-bg">
			<h5 class="headding">Get A Quote</h5>
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">

	<form method="post" [formGroup]="ContactForm" (ngSubmit)="onSubmit()">
              <input type="hidden" data-form-email="true">
              <div class="form-group">
                <input [ngClass]="(submitted && f.name.errors)?'danger':''" type="text" id="fname" formControlName="name" name="name"  class="form-control" placeholder="Name *" value="">
              </div>
              <div class="form-group">
                <input  [ngClass]="(submitted && f.email.errors)?'danger':''" type="email" id="email" formControlName="email" name="email" type="text" class="form-control" placeholder="Email*" value="">
              </div>
              <div class="form-group">
                <input [ngClass]="(submitted && f.phone.errors)?'danger':''"  type="tel" id="tel" formControlName="phone" name="tel" class="form-control" placeholder="Phone*" value="">
              </div>
              <div class="form-group">
                <textarea name="txtMsg"  formControlName="message" class="form-control" placeholder="Message" style="width: 100%; height: 150px;"></textarea>
              </div>
              <div>
                <input  type="submit" name="btnSubmit" class="btnContact" value="Submit" />
                <button class="clos-btn" type="submit" (click)="Quickform=false" class="btn btn-sm btn-dark">Close</button>
              </div>
            </form>
				</div>


			</div>
		</div>
	</div>

	<!--- form End ---->


