import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title }     from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(
        private meta: Meta,
        private titleService: Title,
    ) {

    }

    public addMetaTags(seoArray:any){
        this.meta.addTags(seoArray);
    }
    public  setTitle(newTitle: string){
        this.titleService.setTitle( newTitle );
    }

    public updateDesc(DescObject:any){
        this.meta.updateTag(DescObject);
    }

    public updateTitle(TitleObject:any){
        this.meta.updateTag(TitleObject);
    }

}
