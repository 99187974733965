import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    returnUrl:any;
     openSidebar : boolean = false;
    ProductSwap : boolean = false;
    AboutSwap : boolean = false;
     WhySwap : boolean = false;
  constructor(public router : Router) { }

  ngOnInit(): void {
  }

  aboutrout(){
    this.returnUrl = "/about-us";
    this.router.navigate([this.returnUrl]);
  }

  toogleMenu(){
        if(this.openSidebar) {
            this.openSidebar = false;
        }else {
            this.openSidebar = true;
        }
    }
    tooglePorductSwap(){
        if(this.ProductSwap) {
            this.ProductSwap = false;
        }else {
            this.ProductSwap = true;
        }
    }

    toogleAboutSwap(){
        if(this.AboutSwap) {
            this.AboutSwap = false;
        }else {
            this.AboutSwap = true;
        }
    }
    toogleWhySwap(){
        if(this.WhySwap) {
            this.WhySwap = false;
        }else {
            this.WhySwap = true;
        }
    }
}
    

