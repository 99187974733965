import { Component, OnInit } from '@angular/core';
import { CustomService } from 'src/app/custom.service'; 
import { environment } from 'src/environments/environment';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    api:any;
    Page:any;
    ContactForm:any;
    availablepage = false;
    returnUrl:any;
    submitted = false;

  Quickform= false
  constructor(public customservice:CustomService,private fb: FormBuilder,public router : Router) {
    this.ContactForm= FormGroup
   }

  ngOnInit(): void {

     this.api  = environment;
    this.ContactForm = this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        message: ['',''],
   });

   
  }

  get f() { return this.ContactForm.controls; }  
  onSubmit(){
    this.submitted = true;
    if (this.ContactForm.invalid) {
        return;
    }
    this.customservice.Contact(this.ContactForm.value).subscribe(
        (data:any)=>{
            if (data == '1') {
                this.returnUrl = "/thank-you";
                console.log(this.returnUrl);
                this.Quickform = false;
                this.router.navigate([this.returnUrl]);
            }
        }
    )
}

}

