import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const Api = environment.api;
@Injectable({
  providedIn: 'root'
})
export class CustomService {

  constructor(private http: HttpClient) { }

  Career(feedback){
  let headers = new HttpHeaders();
  headers.append('Access-Control-Allow-Origin','*');
    return this.http.post(Api+'sendecareer',feedback,{headers:headers});
  }
  
  
upload_resume(fileToUpload: File){
    let headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('resume', fileToUpload, fileToUpload.name);
    headers.append('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(Api+'updateresumefile', formData, { headers: headers });
}


   Allblogs(){ 
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin','*');
      return this.http.get(Api+'show_Allblogs',{headers:headers});
    }

    Events(){ 
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin','*');
      return this.http.get(Api+'getEvents',{headers:headers});
    }
  
    Blog(slug: any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
          return this.http.get(Api+'show_blogs/'+slug,{headers:headers});
    }
    getclients(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get( Api + 'getclients',{headers:headers});
    }
    getBanners(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get( Api + 'show_banners',{headers:headers});
    }
    getgallery(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get( Api + 'show_gallery',{headers:headers});
    }
    homeclients(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get( Api + 'show_home_clients',{headers:headers});
    }
    getpages(slug: any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get( Api + 'show_cmsdetial/'+slug,{headers:headers});
    }
    ShowProducts(slug: any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get( Api + 'show_products/'+slug,{headers:headers});
    }
    Contact(feedback: any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(Api+'sendinquiry',feedback,{headers:headers});
      }
}
