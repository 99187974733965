import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
{ path:'',component:HomeComponent},
{ path: 'contact-us', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
{ path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
{ path: 'thank-you',loadChildren: () => import('./thankyou/thankyou.module').then(m => m.ThankyouModule)},
{ path: 'about-us',loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule)},
{ path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule) },
{ path: 'why-choose-us', loadChildren: () => import('./whychooseus/whychooseus.module').then(m => m.WhychooseusModule) },
{ path: 'quality', loadChildren: () => import('./quality/quality.module').then(m => m.QualityModule) },
{ path: 'infrastructure', loadChildren: () => import('./infrastructure/infrastructure.module').then(m => m.InfrastructureModule) },
{ path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
{ path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
{ path: 'career', loadChildren: () => import('./career/career.module').then(m => m.CareerModule) },
{ path: 'vision', loadChildren: () => import('./vision/vision.module').then(m => m.VisionModule) },
{ path: 'green', loadChildren: () => import('./green/green.module').then(m => m.GreenModule) },
{ path: 'value', loadChildren: () => import('./value/value.module').then(m => m.ValueModule) },
{ path: 'qualitycontrol', loadChildren: () => import('./qualitycontrol/qualitycontrol.module').then(m => m.QualitycontrolModule) },
{ path: 'promptcustomer', loadChildren: () => import('./promptcustomer/promptcustomer.module').then(m => m.PromptcustomerModule) },
{ path: 'innovativecreative', loadChildren: () => import('./innovativecreative/innovativecreative.module').then(m => m.InnovativecreativeModule) },
{ path: 'who-we-are', loadChildren: () => import('./who-we-are/who-we-are.module').then(m => m.WhoWeAreModule) },
{ path: '**', redirectTo: '/'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
