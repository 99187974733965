<owl-carousel *ngIf="availablebanners" [options]="{items:1,nav:true,dots:false,navigation: false,autoplay:true,loop:true}" [carouselClasses]=" ['owl-theme', 'main', 'sliding']" >
    <div class="item" *ngFor="let b of Banner">
        <div class="banner">
            <picture>
                <source media="(max-width:600px)" srcset="{{api.Banners+b.MobileBannerImage}}">
                <img src="{{api.Banners+b.BannerImage}}" alt="{{b.BannerTitle}}" width="1600" height="571" class="img-fluid">
              </picture>
        </div>
    </div>
</owl-carousel>

  <section id="product">
<section class="folding">
	<div class="container">
		<div class="fold-title">
		
		<h3>Turning Perception Into a Package</h3>
		<h1>Folding Cartons</h1>
	</div>

<div class="main-farma">
	<div class="pharma">
		<a href="/products/pharmaceuticals">
	<h4>Pharmaceuticals</h4>
	<img width="278" height="304" class="img-fluid" alt="Pharmaceuticals" src="assets/images/folding-cart1.png"></a>
</div>


<div class="pharma">
	<a href="/products/food">
	<h4>Food</h4>
	<img width="278" height="304" alt="Food" class="img-fluid"  src="assets/images/food.png">
</a>
</div>


<div class="pharma">
	<a href="/products/fmcg">
	<h4>FMCG</h4>
	<img width="278" height="304" alt="FMCG" class="img-fluid"  src="assets/images/folding-cart3.png">
</a>
</div>

<div class="pharma">
	<a href="/products/textile">
	<h4>Textiles</h4>
	<img  width="278" height="304" alt="Textiles" class="img-fluid"  src="assets/images/folding-cart4.png">
</a>
</div>
	</div>
</div>
	
</section>
</section>


<section id="other-bottom-pro">
<section class="other-product">
	<div class="container">
		<div class="main-other-product">
		<h2>Other Products</h2>
    <p>Envision Through Printing</p>

<div class="other-pro">
<ul class="pro-list">
	<li><a href="/products/paper-labels">Paper<br>Labels</a></li>
	<li><a href="/products/promotional-material">Promotional <br>Material</a></li>
	<li class="calen-inn"><a href="/products/calendar-printing">Calendars</a></li>
	<li><a href="/products/laminate-folders">Laminate <br>Folders</a></li>
	<li class="calen-inn"><a href="/products/leaflet-printing">Leaflets<br></a></li>
	<li class="calen-inn"><a href="/products/book-printing">Books<br></a></li>
	<li class="pag-bag"><a href="/products/paper-bags">Paper <br>Bags</a></li>

</ul>
</div>
</div>

	</div>
	
</section>
</section>
<section class="about-us">
<div class="container">
	<div class="fold-title">
		
		<h3>Best In Class Infrastructure</h3>
			
	</div>

<div class="abou-text-best">
		<p>We are in the business of printing and packaging since 1995. That’s over 20 years of trust and quality. </p>

		<p>150 specialists work round the clock at our 60,000 square feet plant.</p>
		<p>With a product portfolio that includes folding cartons, labels, leaflets, books and promotional materials like brochures, catalogues and calendars, <br>
we endeavour to harvest the skills and experience of our members and engage in a focused dialogue with our clients, <br>
leading to an enhancement of the value of our clients' products.</p>

<!--<a class="more-btn buttonfx curtainup" href="/about-us">Read More <i class="fas fa-caret-right"></i></a>-->
</div>
</div>
</section>

<section class="box">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-4 col-sm-12 col-12">
				<div class="box-image">
				<img width="360" alt="Infrastructure" height="275" src="assets/images/box1.jpg" class="w-100">
			</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-12 col-12">
					<div class="box-image">
				<img width="360" alt="Infrastructure" height="275" src="assets/images/box2.jpg" class="w-100">
			</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-12 col-12">
					<div class="box-image">
				<img width="360" alt="Infrastructure" height="275" src="assets/images/box3.jpg" class="w-100">
			</div>
			</div>
		</div>
	</div>
	
</section>




<section class="whychoos">
<div class="container">

		<h2>Why Choose Us?</h2>
<div class="row">
	<div class="col-lg-3 col-md-3 col-sm-6 col-6">
<div class="main-box">

  <div class="content">
    <a href="/value">
      <div class="content-overlay"></div>
      <img class="content-image" width="166" height="166" class="img-fluid" src="assets/images/whychoos-1.png">

      <div class="content-details fadeIn-top">
       
      <!--<div class="modal-body">
       <p>Foil stamping<br>
Embossing<br>
Metallised printing (UV printing on metallised polyester)<br>
Window patching<br>
Drip off effect<br>
Online aqueous varnish (gloss, matt, pearlescent)<br>
Hot melt gluing<br>
LDPE coated cartons<br>
Water resistant cartons</p>
      </div>-->
      </div>
   

<div class="whaychoos-box">
<p>Value Added Products at<br>
Competitive Prices </p>
</div>
 </a>

  </div>
  
 </div>
   
</div>



<div class="col-lg-3 col-md-3 col-sm-6 col-6">
<div class="main-box">

  <div class="content">
    <a href="/qualitycontrol">
      <div class="content-overlay"></div>
      <img class="content-image" src="assets/images/whychoos-2.png"  width="166" height="166" class="img-fluid">

      <div class="content-details fadeIn-top">
    <!--   <div class="modal-body">
    <p>Combination of the right machinery, systems and trained manpower ensure consistent quality</p><br>
     <p>Documented standard operating procedures for every activity</p><br>
     <p>Regular training and skill upgradation programs for the staff</p><br>
     <p>In house quality control laboratory with the latest testing equipments</p>
      </div>-->
      </div>
   

<div class="whaychoos-box">
<p>Quality</p>
</div>
 </a>
 
  </div>
  
 </div>
   
</div>



<div class="col-lg-3 col-md-3 col-sm-6 col-6">
<div class="main-box">

  <div class="content">
    <a href="/promptcustomer">
      <div class="content-overlay"></div>
      <img class="content-image" src="assets/images/whychoos-3.png"  width="166" height="166" class="img-fluid">

      <div class="content-details fadeIn-top">
     <!--   <div class="modal-body">
      <p>Online Job Tracking facility<br>
      	Prompt and pro-active customer care team<br>
      	Shorter delivery lead period.<br>
Quick quotes


</p>
      </div>-->
      </div>
    

<div class="whaychoos-box">
<p>Prompt Customer<br>
Service</p>
</div>
</a>

  </div>
  
 </div>
   
</div>



<div class="col-lg-3 col-md-3 col-sm-6 col-6">
<div class="main-box">

  <div class="content">
    <a href="/innovativecreative">
      <div class="content-overlay"></div>
      <img class="content-image" src="assets/images/whychoos-4.png"  width="166" height="166" class="img-fluid">

      <div class="content-details fadeIn-top">
    <!--<div class="modal-body">
       <p>Experienced and qualified creative team offers attractive design options along with the right structure and engineering lay outs.</p><br>
       <p>The design team suggests the right substrate depending upon the size, weight , stacking and packing need of the product. They ensure that resources are not wasted by selecting a substrate higher or lower than the requirement.</p><br>
       <p>We enable saving on logistics cost by providing high bulk boards that are lighter in weight.</p><br>
       <p>Neatly made mock ups help the client see, touch and feel the product before</p>
      </div>-->
      </div>
   

<div class="whaychoos-box">
<p>Innovative & Creative <br>
Packaging Solutions</p>
</div>

 </a>
  </div>
  
 </div>
   
</div>


</div>
</div>

</section>


<section class="our-clients" *ngIf="availableclients">
	<div class="container">
		<h2>Our Clients</h2>
		<p>more than 130 customers across 7 countries.</p>
		<div class="client mt-5" >
	

   <owl-carousel  [options]="refernce"  [carouselClasses]=" ['owl-theme', '', 'sliding']" >
            <div class="item cli-in" *ngFor="let c of Clients"> 
                
                    <img width="165" height="119" class="img-fluid" src="{{api.ClientsImage + c.Image}}">
                
            </div>
           
        </owl-carousel>

</div>
	</div>	
</section>

<section class="main-map">
<div class="map">
	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29400.628783480854!2d72.41884152481123!3d22.910471640615064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84e392f93a33%3A0xe6d08aa1b41cccae!2sOnline%20Print%20%26%20Pack%20Private%20Limited!5e0!3m2!1sen!2sin!4v1627367313388!5m2!1sen!2sin" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>
</section>






<!-- Modal -->
<div class="modal fade" id="value" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Value Added Products At Competitive Prices</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <p>Foil stamping<br>
Embossing<br>
Metallised printing (UV printing on metallised polyester)<br>
Window patching<br>
Drip off effect<br>
Online aqueous varnish (gloss, matt, pearlescent)<br>
Hot melt gluing<br>
LDPE coated cartons<br>
Water resistant cartons</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <!-- <button type="button" class="btn btn-primary">Save changes</button>-->
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="quality" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Quality</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
     <p>Combination of the right machinery, systems and trained manpower ensure consistent quality</p><br>
     <p>Documented standard operating procedures for every activity</p><br>
     <p>Regular training and skill upgradation programs for the staff</p><br>
     <p>In house quality control laboratory with the latest testing equipments</p>
      </div>
      <div class="modal-footer">
       <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
       <!-- <button type="button" class="btn btn-primary">Save changes</button>-->
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="prompt" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Prompt Customer Service</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <p>Online Job Tracking facility<br>
      	Prompt and pro-active customer care team<br>
      	Shorter delivery lead period.<br>
Quick quotes


</p>
      </div>
      <div class="modal-footer">
       <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
       <!-- <button type="button" class="btn btn-primary">Save changes</button>-->
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="innovative" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Innovative & Creative Packaging Solutions
</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <p>Experienced and qualified creative team offers attractive design options along with the right structure and engineering lay outs.</p><br>
       <p>The design team suggests the right substrate depending upon the size, weight , stacking and packing need of the product. They ensure that resources are not wasted by selecting a substrate higher or lower than the requirement.</p><br>
       <p>We enable saving on logistics cost by providing high bulk boards that are lighter in weight.</p><br>
       <p>Neatly made mock ups help the client see, touch and feel the product before</p>
      </div>
      <div class="modal-footer">
        <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
       <!-- <button type="button" class="btn btn-primary">Save changes</button>-->
      </div>
    </div>
  </div>
</div>